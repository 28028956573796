import { cva } from 'cva'
import { twMerge } from 'tailwind-merge'

const button = cva('button', {
  variants: {
    colour: {
      chartreuse: ['before:bg-char', 'after:bg-char', 'text-blue-d'],
      'ink blue': ['before:bg-blue-d', 'after:bg-blue-d', 'text-white'],
      'mid blue': ['before:bg-blue-m', 'after:bg-blue-m', 'text-white'],
      'light blue': ['before:bg-blue-l', 'after:bg-blue-l', 'text-blue-d'],
      green: ['before:bg-green', 'after:bg-green', 'text-blue-d'],
      purple: ['before:bg-purple', 'after:bg-purple', 'text-blue-d'],
      pink: ['before:bg-pink', 'after:bg-pink', 'text-blue-d'],
      orange: ['before:bg-orange', 'after:bg-orange', 'text-blue-d']
    }
  },
  defaultVariants: {
    colour: 'pink'
  }
})

const Button = ({ colour, ...props }: Button) => {
  return (
    <div
      className={[
        twMerge(
          button({ colour }),
          'relative w-full text-center sm:w-fit',
          'before:absolute before:left-0 before:top-1/2 before:z-[1] before:aspect-square before:h-3 before:-translate-y-1/2 before:transition-all',
          'after:absolute after:right-0 after:top-0 after:z-[0] after:h-full after:w-[calc(100%-15px)] after:transition-all',
          'focus-within:after:w-[calc(100%-30px)] hover:after:w-[calc(100%-30px)]'
        )
      ].join(' ')}
    >
      <a
        href={
          props?.btn?.externalLink === null
            ? props?.btn?.entry?.sys.uri
            : props?.btn?.externalLink
        }
        {...(props?.btn?.ariaLabel !== ''
          ? { 'aria-label': props?.btn?.ariaLabel }
          : {})}
        className={[
          twMerge(
            button({ colour }),
            'relative z-[1] block h-full px-6 py-4 text-tag font-bold uppercase leading-[1.5] no-underline outline-none transition-all md:text-base',
            'before:absolute before:left-0 before:top-0 before:h-[calc(100%/2-0.35rem)] before:w-5 before:transition-all',
            'after:absolute after:bottom-0 after:left-0 after:h-[calc(100%/2-0.35rem)] after:w-5 after:transition-all',
            'hover:pl-[40px] hover:text-current hover:before:left-[15px] hover:after:left-[15px]',
            'focus-within:pl-[40px] focus-within:before:left-[15px] focus-within:after:left-[15px]'
          )
        ].join(' ')}
        {...(props?.btn?.target ? { target: '_blank' } : {})}
        {...(props?.btn?.target ? { rel: 'noreferrer nofollow' } : {})}
      >
        {props?.btn?.label}
      </a>
    </div>
  )
}

export default Button
